import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => {
  return(
    <Layout>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>Oops! The page you were looking for doesn't exist</p>
      <Link to="/">Go home</Link>
    </Layout>
  );
};

export default NotFoundPage;
